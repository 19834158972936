.footer {
    background-color: #333;
    color: #fff;
    padding: 1rem 0;

    .container {
        max-width: 1200px;
        margin: auto;
        padding: 0 1rem;

        .footer-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .footer-section {
                flex: 1;
                margin: 0.5rem;
                text-align: center;

                h3 {
                    margin-bottom: 0.5rem;
                    font-size: 1.4rem;
                }

                p,
                ul {
                    margin: 0;
                    color: #fff;
                    font-size: 0.9rem;
                }

                ul {
                    list-style: none;
                    padding: 0;

                    li {
                        margin-bottom: 0.5rem;

                        a,
                        span {
                            color: #fff;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &.contact {
                    background-color: #333;
                    padding: 1rem;
                    border-radius: 8px;
                }

                &.map {
                    iframe {
                        border: 0;
                        width: 100%;
                        height: 250px;
                    }
                }
            }
        }
    }

    .footer-bottom {
        background-color: #222;
        padding: 1rem 0;
        text-align: center;
        width: 100%;

        p {
            margin: 0;
            color: #fff;
            font-size: 0.9rem;
        }
    }
}

@media (max-width: 768px) {
    .footer .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer .footer-section {
        margin: 0.5rem 0;
        text-align: center;
    }

    .footer .footer-section h3 {
        font-size: 1.3rem;
    }

    .footer .footer-section.map iframe {
        height: 200px;
    }
}

@media (max-width: 576px) {
    .footer .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer .footer-section {
        margin: 0.5rem 0;
        width: 100%;
        text-align: center;
    }

    .footer .footer-section h3 {
        font-size: 1.2rem;
    }

    .footer .footer-section.map iframe {
        height: 180px;
    }

    .footer .container {
        padding: 0 0.5rem;
        margin-right: 12%;
    }
}