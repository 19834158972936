.about-me-page {
    font-family: 'Arial', sans-serif;
    color: #333;

    .about-me,
    .experience,
    .skills {
        padding: 4rem 0;

        .container {
            width: 90%;
            max-width: 1200px;
            margin: 0 auto;
            text-align: center;

            h2 {
                font-size: 2.5rem;
                margin-bottom: 1.5rem;
                position: relative;
                display: inline-block;

                &::after {
                    content: '';
                    display: block;
                    width: 50px;
                    height: 3px;
                    background-color: #007bff;
                    margin: 10px auto;
                }
            }
        }
    }

    .about-me {
        background: rgb(247, 247, 247);
        background: linear-gradient(0deg, rgba(247, 247, 247, 0.9501050420168067) 0%, rgba(34, 110, 195, 0.09576330532212884) 71%);
        
        p {
            max-width: 800px;
            margin: 0 auto;
            line-height: 1.8;
            font-size: 1.1rem;
            color: #666;
            transition: color 0.3s;
        }
    }

    .experience {
        background-color: #f7f7f7;

        ul {
            list-style: none;
            padding: 0;
            max-width: 800px;
            margin: 0 auto;

            li {
                margin-bottom: 2rem;
                background: #fff;
                padding: 1rem;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                transition: transform 0.3s, box-shadow 0.3s;

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
                }

                h3 {
                    font-size: 1.8rem;
                    margin-bottom: 0.5rem;
                }

                p {
                    margin: 0.5rem 0 0;
                    color: #777;
                }
            }
        }
    }

    .skills {
        background: rgb(98, 34, 195);
        background: linear-gradient(0deg, rgba(98, 34, 195, 0.11817226890756305) 0%, rgba(247, 247, 247, 1) 100%);

        ul {
            list-style: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;

            li {
                background: rgb(180, 244, 244);
                background: radial-gradient(circle, rgba(180, 244, 244, 0.10696778711484589) 10%, rgba(255, 255, 255, 0.10976890756302526) 78%);
                padding: 1rem 1.5rem;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                transition: transform 0.3s, box-shadow 0.3s;
                font-size: 1.1rem;

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    @media (max-width: 768px) {

        .about-me,
        .experience,
        .skills {
            padding: 2rem 0;

            .container {
                padding: 0 1rem;
            }
        }

        .experience ul,
        .skills ul {
            flex-direction: column;
            align-items: center;
        }
    }
}