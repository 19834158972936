.projects {
    .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 1rem;

        h2 {
            text-align: center;
            margin-bottom: 2rem;
        }

        ul {
            list-style: none;
            padding: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 15px;

            li {
                background: #f9f9f9;
                border: 1px solid #ddd;
                border-radius: 8px;
                padding: 1rem;
                margin-bottom: 2rem;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                width: 28%;
                height: auto;
                display: flex;
                flex-direction: column;

                h3 {
                    margin-top: 0;
                }

                p {
                    font-size: 1rem;
                    line-height: 1.5;
                    flex-grow: 1;
                    margin-bottom: 0;
                }

                a {
                    color: #007bff;
                    text-decoration: none;
                    font-size: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 1rem;

                    &:hover {
                        text-decoration: none;
                    }

                    i {
                        font-size: 2rem;
                        color: #333;
                    }
                }

                .slider-container {
                    margin-top: 1rem;
                    position: relative;

                    .slick-prev,
                    .slick-next {
                        color: #ff0707;
                        z-index: 1;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 0;
                        width: 30px;
                        height: 30px;
                        background-color: rgba(0, 0, 0, 0.5);
                        border-radius: 50%;

                        &:before {
                            font-size: 20px;
                            color: #fff;
                        }
                    }

                    .slick-prev {
                        left: 10px;
                    }

                    .slick-next {
                        right: 10px;
                    }

                    .slick-dots {
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                        bottom: -25px;
                        visibility: visible;

                        li {
                            visibility: hidden;

                            button {
                                width: 10px;
                                height: 10px;
                                margin: 0 5px;


                                &:before {
                                    font-size: 10px;
                                    color: #007bff;
                                }
                            }

                            &.slick-active button:before {
                                color: #0056b3;
                            }
                        }
                    }

                    img {
                        width: 100%;
                        border-radius: 8px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .container {
            ul {
                li {
                    width: calc(50%);
                }
            }
        }
    }

    @media (max-width: 576px) {
        .container {
            ul {
                li {
                    width: 90%; // One item per row on very small screens
                }
            }
        }
    }
}