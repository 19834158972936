body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

h2 {
    color: #007BFF;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}