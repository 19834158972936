body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.header {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0;
    width: 100%;
    position: relative;
    z-index: 10;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        box-sizing: border-box;
    }

    .logo {
        order: 2;
        width: 40%;

        img {
            height: 50px;
            display: block;
            float: right;
        }
    }

    .hamburger {
        display: none;
        order: 2;
        flex-direction: column;
        justify-content: space-between;
        width: 24px;
        height: 18px;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 11;

        .bar {
            width: 100%;
            height: 3px;
            background-color: #333;
            transition: all 0.3s;
        }

        &.open .bar:nth-child(1) {
            transform: rotate(45deg) translate(5px, 5px);
        }

        &.open .bar:nth-child(2) {
            opacity: 0;
        }

        &.open .bar:nth-child(3) {
            transform: rotate(-45deg) translate(5px, -5px);
        }
    }

    nav {
        order: 1;
        width: 60%;

        ul {
            list-style: none;
            display: flex;
            gap: 1rem;
            margin: 0;
            padding: 0;

            li a {
                color: #000000;
                text-decoration: none;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                transition: background 0.3s;

                &:hover {
                    background: #f0f0f0;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .container {
            align-items: center;
            flex-direction: row-reverse;
        }

        .hamburger {
            display: flex;
            order: 2;
            margin: 10px;
        }

        nav {
            order: 2;
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.7);
            z-index: 9;
            width: 40%;
            padding: 25px;


            &.open {
                display: flex;
                flex-direction: column;
            }

            ul {
                flex-direction: column;
                width: 100%;

                li a {
                    color: #f0f0f0;
                }
            }

            li {
                width: 100%;
                text-align: center;

            }

            li a {
                width: 100%;
                padding: 1rem;
            }
        }
    }

    @media (max-width: 576px) {
        .logo {
            order: 2;

            img {
                height: 40px;
            }
        }

        nav ul {
            gap: 0.5rem;
        }

        li a {
            padding: 0.5rem;
        }
    }
}